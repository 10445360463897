/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../../../app/slice/authSlice";
import { getData } from "../../../utils/LocalStorage/LocalStorageManager";
import { getCookie } from "../../../utils/Cookies/CookieHandler";

import "./Sidebar.scss";

const Sidebar = (props) => {
  const cookie = getCookie("AuthUser");
  const userID = cookie?.user?.lUserKey;
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [navigationMenuList, setNavigationMenuList] = useState([]);

  useEffect(() => {
    if (cookie?.isAuthenticated) {
      const navigationMenu = getData("NavigationMenu");
      const menu = navigationMenu?.filter((item) => item.lMenuKey === 1);
      setNavigationMenuList(menu);
    }
  }, [userID, cookie?.isAuthenticated]);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const firstPathSegment = pathParts[1];

    const activeMenu = navigationMenuList.find((item) =>
      item.sPage.includes(firstPathSegment)
    );

    if (activeMenu) {
      setSelectedMenu(activeMenu.lMenuItemKey);
    } else {
      setSelectedMenu(null);
    }
  }, [location.pathname, navigationMenuList]);

  const handleClick = (menu) => {
    setSelectedMenu(menu === selectedMenu ? null : menu);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="sidebar-section">
      <nav className="sidebar">
        <div className="main-menus">
          <div className="sidebar-menu">
            <ul className="sidebar-menu-list">
              {navigationMenuList.map((menuItem, index) => (
                <li
                  key={index + 1}
                  className={
                    selectedMenu === menuItem.lMenuItemKey
                      ? "menu-item active-menu"
                      : "menu-item"
                  }
                >
                  <button className="style-0" onClick={() => handleClick(menuItem.lMenuItemKey)}>
                    <Link
                      to={menuItem.sPage}
                      className={`${menuItem.children && "menu-arrow"}`}
                    >
                      <i className={menuItem.sIconClass}></i>
                      <span>{menuItem.sMenuItemCaption}</span>
                    </Link>
                  </button>
                </li>
              ))}
            </ul>
            <div className="bottom-menu">
              <ul className="sidebar-menu-list">
                <li className="menu-item">
                  <Link>
                    <i className="bi bi-lock"></i>
                    <span>Change Password</span>
                  </Link>
                </li>
                <li className="menu-item">
                  <button className="style-0" onClick={handleLogout}>
                    <Link>
                      <i className="bi bi-power"></i>
                      <span>Sign Out</span>
                    </Link>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
