import React from 'react'
import AppRoutes from './layouts/navigation/AppRoutes'
import { ComponentNavigation } from './layouts/navigation/ComponentNavigation'
import { ServiceLocationProvider } from './utils/context/ServiceLocationContext'

const App = () => {
  return (
    <div>
      <ServiceLocationProvider>
        <AppRoutes componentRoutes={ComponentNavigation} />
      </ServiceLocationProvider>
    </div>
  )
}

export default App