import PropTypes from "prop-types";
import React from "react";

function Image(props) {
  return (
    <img
      id={props.imgId}
      src={props.imagePath}
      alt={props.altText}
      className={`img-fluid  ${props.imgCustomClassName}`}
    />
  );
};

Image.propTypes = {
  imgId: PropTypes.string,
  imagePath: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  imgCustomClassName: PropTypes.string
};

export default Image;
