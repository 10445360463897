/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from "react";
import Sidebar from "./components/sidebar/Sidebar";
import { Outlet, useLocation } from "react-router-dom";

import { hasPermission } from "../utils/AuthorizeNavigation/authorizeNavigation2";

import Unauthorize from "../pages/unauthorize/Unauthorize";
import HeaderBanner from "./components/headerBanner/HeaderBanner";
import PageLoader from "../components/ui/pageLoader/PageLoader";
import PropTypes from "prop-types";

const Layout = (props) => {

  const location = useLocation();

  let routePath = location.pathname;
  let foundRoute = null;

  props.componentRoutes.find((x) => {
    if (x.path.toLowerCase() === routePath.toLowerCase()) {
      foundRoute = x;
      return foundRoute;
    } else if (x.children && x.children.length > 0) {
      let childMenuRoute = x.children.find(
        (childItem) =>
          childItem.path.toLowerCase() === routePath.toLowerCase()
      );
      if (childMenuRoute) {
        foundRoute = childMenuRoute;
        return foundRoute;
      }
    }
    return foundRoute;
  })


  const isAuthorize = hasPermission(foundRoute?.securityKey);

  return (
    <>
      <HeaderBanner />
      <div className="main-page-layout">
        <Sidebar componentRoutes={props.componentRoutes} />
        <div className={`middle-page-section`}>
          {isAuthorize && (isAuthorize.isView || isAuthorize.isEdit)
            ? (
              <div className="center-content-part">
                <Suspense fallback={<PageLoader />}>
                  <Outlet />
                </Suspense>
              </div>
            )
            : <Unauthorize />
          }
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  componentRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string.isRequired,
          securityKey: PropTypes.string,
        })
      ),
      securityKey: PropTypes.string,
    })
  ).isRequired,
};

export default Layout;
