// Import the `getData` function from 'LocalStorageManager' to get security permission data.
import { getData } from '../LocalStorage/LocalStorageManager';

/**
 * Checks if a user has permission based on a security key.
 * @param {string} securityKey - The security key to check for permission.
 * @returns {boolean} - `true` if the user has permission, `false` otherwise.
 */
export const hasPermission = (securityKey) => {
    // Retrieve security permission data from local storage, or initialize as an empty array if not found.
    const permissionData = getData("NavigationMenu") || []; // navigation menu holds the security permissions

    // Assign the security permissions to the `securityPermission` variable.
    const securityPermission = permissionData;

    // Initialize `obj` with `securityPermission`, or `null` if it's falsy.
    const permissionList = securityPermission || null;

    // Call the `findKeyByKeyName` function to check if the security key exists.
    const hasAccess = securityPermission ? findKeyByKeyName(permissionList, securityKey) : false;

    return hasAccess; // Return the result of the check.
}

// hasPermission(1)

/**
 * Finds a security key in the security permission data and checks if the user has permission.
 * @param {Object} securityPermissions - The object containing security permission data.
 * @param {number} targetSecurityKey - The security setting key to search for.
 * @returns {boolean} - `true` if the user has permission, `false` otherwise.
 */
function findKeyByKeyName(securityPermissions, targetSecurityKey) {
    // debugger
    let permissionObj = {
        noPermission: false,
        isView: false,
        isEdit: false,
    }

    if (!securityPermissions) {
        return false; // If `securityPermissions` is not available, return `false`.
    }

    // Find the security permission with the specified key.
    const foundItem = securityPermissions.find(item =>
        item.lMenuItemKey === targetSecurityKey
    );

    if (foundItem) {
        // Check the lSecuritySettingKey and return `true` for certain values.
        switch (foundItem.lSecuritySettingKey) {
            case 1:
                return { ...permissionObj, noPermission: true };
            case 2:
                return { ...permissionObj, isView: true };
            case 3:
                return { ...permissionObj, isEdit: true };
            default:
                return permissionObj;
        }
    } else {
        return null;
    }
}
