import { createContext, useEffect, useMemo, useState } from "react";
import { getAuthProps } from "../../lib/authenticationLibrary";
import PropTypes from "prop-types";

const ServiceLocationContext = createContext();

const ServiceLocationProvider = ({ children }) => {

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isLocationUpdated, setIsLocationUpdated] = useState(false);

    const userDetails = getAuthProps();
    const loggedInUserServiceLocation = userDetails?.user?.lServiceLocationKey || 0;

    useEffect(() => {
        if (loggedInUserServiceLocation) {
            setSelectedLocation(loggedInUserServiceLocation);
        }
    }, [loggedInUserServiceLocation]);

    const value = useMemo(() => ({
        selectedLocation,
        setSelectedLocation,
        isLocationUpdated,
        setIsLocationUpdated,
    }), [selectedLocation, setSelectedLocation, isLocationUpdated, setIsLocationUpdated,]);

    return (
        <ServiceLocationContext.Provider value={value}>
            {children}
        </ServiceLocationContext.Provider>
    );

}


ServiceLocationProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { ServiceLocationProvider, ServiceLocationContext }