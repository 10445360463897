import React from "react";
import DropdownSelect from "./DropdownSelect"
import PropTypes from "prop-types";

const DropDown = (props) => {


  const defaultSelectedOption = props.value ? props?.options?.find(option => option.value === props.value) : null;

  const defaultSelectedOptionMultiSelect = Array.isArray(props.value) ?
    props.options.filter(option => props.value.includes(option.value)) : [];

  return (
    <span className="d-inline-block custom-input">
      <DropdownSelect
        placeholder={props.placeholder}
        isMultiSelect={props.isMultiSelect}
        optionsValue={props.options} // Define selectValue according to your needs
        value={props.isMultiSelect ? defaultSelectedOptionMultiSelect : defaultSelectedOption} // Pass the selected value from the parent component
        handleDropdownChange={props.onChange} // Handle dropdown value change
        handleDropdownBlur={props.onBlur}
        isDropdownDisabled={props.isDisabled}
      />
    </span>
  );
}

DropDown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string, // For single-select dropdown
    PropTypes.arrayOf(PropTypes.string), // For multi-select dropdown
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired, // Callback function for handling change
  onBlur: PropTypes.func, // Optional callback function for handling blur
  isDisabled: PropTypes.bool, // For disabling the dropdown
};

DropDown.defaultProps = {
  value: null,
  placeholder: "Select...",
  isMultiSelect: false,
  onBlur: () => { },
  isDisabled: false,
};

export default DropDown