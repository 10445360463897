import React from "react";
import Select from "react-select";
import "./DropdownSelect.scss";
import PropTypes from "prop-types";

function DropdownSelect(props) {

  return (
    <span className="d-inline-block custom-input">
      <Select
        className="custom-checkbox-select"
        value={props.value}
        onChange={props.handleDropdownChange}
        onBlur={props.handleDropdownBlur}
        options={props.optionsValue}
        isMulti={props.isMultiSelect}
        placeholder={props.placeholder}
        isDisabled={props.isDropdownDisabled}
        menuPortalTarget={document.body}
        menuPosition="absolute"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
      />
    </span>
  );
}

DropdownSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  handleDropdownChange: PropTypes.func.isRequired,
  handleDropdownBlur: PropTypes.func,
  optionsValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  isMultiSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  isDropdownDisabled: PropTypes.bool,
};

export default DropdownSelect;
