// Import necessary dependencies and utilities
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create client API using createApi function from Redux Toolkit
const inventoryAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'inventoryAPI',
    endpoints: (builder) => ({

        getInventoryList: builder.mutation({
            query: (data) => ({
                url: `/Inventory/GetAllInventoryList`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getInventoryItemDetailsById: builder.query({
            query: (data) => ({
                url: `/Inventory/GetInventoryById?plInventoryKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addInventoryItem: builder.mutation({
            query: (data) => ({
                url: `/Inventory/AddInventory`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateInventoryItem: builder.mutation({
            query: (data) => ({
                url: `/Inventory/UpdateInventory`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteInventoryValidation: builder.mutation({
            query: (data) => ({
                url: `/Inventory/DeleteInventoryValidation?plInventoryKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteInventory: builder.mutation({
            query: (data) => ({
                url: `/Inventory/DeleteInventory?plInventoryKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllInventorySizesList: builder.mutation({
            query: (data) => ({
                url: `/Inventory/GetAllInventorySizesList`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getInventorySizeDetailsById: builder.query({
            query: (data) => ({
                url: `/Inventory/GetAllInventorySizesById?plInventorySizeKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addInventorySize: builder.mutation({
            query: (data) => ({
                url: `/Inventory/AddInventorySize`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateInventorySize: builder.mutation({
            query: (data) => ({
                url: `/Inventory/UpdateInventorySize`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteInventorySizeValidation: builder.mutation({
            query: (data) => ({
                url: `/Inventory/DeleteInventorySizeValidation?plInventorySizeKey=${data}`,
                method: 'DELETE',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteInventorySize: builder.mutation({
            query: (data) => ({
                url: `/Inventory/DeleteInventorySize?plInventorySizeKey=${data}`,
                method: 'DELETE',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Inventory Build
        getInventoryBuildLotNumber: builder.query({
            query: (data) => ({
                url: `/Inventory/GetInventoryBuildLotNumber?plUserKey=${data.plUserKey}&plSessionID=${data.plSessionID}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getInventorySizesFromLotNumber: builder.query({
            query: (data) => ({
                url: `/Inventory/GetInventorySizesFromLotNumber?psLotNumber=${data.psLotNumber}&pbPositiveQuantityOnly=${data.pbPositiveQuantityOnly}`,
                method: "GET",
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),


        addInventorySizeBuild: builder.mutation({
            query: (data) => ({
                url: `/Inventory/AddInventorySizeBuild`,
                method: "POST",
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
});


export const {
    useGetInventoryListMutation,
    useLazyGetInventoryItemDetailsByIdQuery,
    useAddInventoryItemMutation,
    useUpdateInventoryItemMutation,
    useDeleteInventoryValidationMutation,
    useDeleteInventoryMutation,
    useGetAllInventorySizesListMutation,
    useLazyGetInventorySizeDetailsByIdQuery,
    useAddInventorySizeMutation,
    useUpdateInventorySizeMutation,
    useDeleteInventorySizeValidationMutation,
    useDeleteInventorySizeMutation,

    useLazyGetInventoryBuildLotNumberQuery,
    useLazyGetInventorySizesFromLotNumberQuery,
    useAddInventorySizeBuildMutation,
} = inventoryAPI;

export default inventoryAPI;



