/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

/** Common Components */
import Image from "../../../components/image/Image";
import ServiceLocationFilter from "../../../components/filters/ServiceLocationFilter";

/** Common Services & Data files */
import { AppIcons } from "../../../data/appIcons";

/** Utilities */
import { getAuthProps } from "../../../lib/authenticationLibrary";
import { ServiceLocationContext } from "../../../utils/context/ServiceLocationContext";

/** CSS files */
import "./HeaderBanner.scss";

const HeaderBanner = () => {

  const { selectedLocation, setSelectedLocation, isLocationUpdated } = useContext(ServiceLocationContext);

  const userDetails = getAuthProps();

  let loggedInUserName;
  if (userDetails?.user?.sUserFullName) {
    loggedInUserName = userDetails.user.sUserFullName;
  } else if (userDetails?.user?.sUserName) {
    loggedInUserName = userDetails.user.sUserName;
  } else {
    loggedInUserName = "User";
  }
  const loggedInUserServiceLocation = userDetails?.user?.lServiceLocationKey ?? 0;

  const handleOnChange = (lServiceLocationKey) => {
    setSelectedLocation(lServiceLocationKey);
  };

  useEffect(() => {
    if (loggedInUserServiceLocation) {
      setSelectedLocation(loggedInUserServiceLocation);
    }
  }, [loggedInUserServiceLocation]);

  return (
    <div className="header-banner-section">
      <div className="top-user-name">
        <h6>
          Welcome, <span>{loggedInUserName}</span>
        </h6>
      </div>
      <div className="center-logo-sec">
        <Image imagePath={AppIcons.logoImageLight} altText="Banner Image" />
      </div>
      <div className="right-dropdown">
        <div className="service-location-sec">
          <ServiceLocationFilter
            isLocationUpdated={isLocationUpdated}
            // isDisable={true}
            selectedLocation={selectedLocation}
            onLocationChange={handleOnChange}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
