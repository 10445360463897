import { decryptAES } from "../../services/CryptoService";
import SwalAlert from "../../services/swalService/SwalService";
import ToastService from "../../services/toastService/ToastService";

const { error } = SwalAlert();

export const transformSuccessResponse = async (response, meta, arg) => {
    let resData = response.responseData;

    if (response.isEnType) {
        resData = decryptAES(resData);
    } else if (meta.request.url.includes('Export') || meta.request.url.includes('GetCityStateUSA')) {
        return response;
    }

    const apiData = meta.request.url.includes('Export') || meta.request.url.includes('GetCityStateUSA')
        ? resData
        : JSON.parse(resData);

    if (apiData.statusCode === 200) {
        return apiData.data;
    } else {
        error(apiData.message || "Something went wrong with API. Please contact admin!!");
        return null;
    }
};

export const transformErrorResponse = (response, meta, args) => {
    if (response.status === "FETCH_ERROR") {
        return handleFetchError();
    }

    if (response.status === "PARSING_ERROR" && response.originalStatus === 500) {
        return handleParsingError(response);
    }

    if (response.status && response.data.responseData) {
        const resData = processResponseData(response);
        handleStatusErrors(response, resData);
    }

    return null;
};


/**
 * 
 * Helper methods for error response handling
 * 
 */
const handleFetchError = () => {
    ToastService.error("Oops! Error while fetching data from the server. Please try again later.");
    return null;
};

const handleParsingError = (response) => {
    const errorMessage = response.data.toLowerCase();
    if (errorMessage.includes("sql server") || errorMessage.includes("connection") || errorMessage.includes("provider")) {
        ToastService.error(
            "Oops! There was an issue with the SQL Server or database connection. Please try again later and ensure that the SQL Server is configured correctly."
        );
    } else {
        error("Oops! There was an issue processing the server's response. Please try again later.");
    }
    return null;
};

const processResponseData = (response) => {
    let resData = response.data.responseData;

    if (response.data.isEnType) {
        resData = decryptAES(resData);
    } else {
        resData = JSON.parse(resData);
    }

    return resData;
};

const handleValidationError = (resData) => {
    let errorMessage = `Validation Error: ${resData.message}`;
    for (const fieldName in resData.errors) {
        if (resData.errors.hasOwnProperty(fieldName)) {
            errorMessage += `\n ${fieldName} : ${resData.errors[fieldName].join("\n")} `;
        }
    }
    error(errorMessage);
};

const handleStatusErrors = (response, resData) => {
    if (response.status === 400 && resData.errors) {
        handleValidationError(resData);
    } else if (response.status === 500) {
        error("Something went wrong with the API call! Please contact admin.");
    }
};