// Menus & Header Images

// Others
import calender from "../assets/images/calender.png";
import logo from "../assets/images/logo.png";
import logoWhite from "../assets/images/logo-white.png";
import edit from "../assets/images/editIcon.png";
import deleted from "../assets/images/deleteIcon.png";
import copy from "../assets/images/copy.png";
import search from "../assets/images/search.png";
import notification from "../assets/images/notification.png";
import arrow from "../assets/images/arrow.png";
import arrowRight from "../assets/images/arrow-right.png";
import shortingArrow from "../assets/images/shortingArrow.png";
import noRecord from "../assets/images/noRecord.png";
import documenticon from "../assets/images/document-icon.png";

import saveicon from "../assets/images/save-icon.png";
import deleteBtn from "../assets/images/delete.png";
import addIcon from "../assets/images/add-icon.png";
import closeIcon from "../assets/images/close-icon.png";
import closeIconR from "../assets/images/close-icon-r.png";
import binoculars from "../assets/images/Binoculars.png";
import deletecolor from "../assets/images/deletecolor.png";
import printer from "../assets/images/printer.png";
import searchcolor from "../assets/images/searchcolor.png";
import searchDocument from "../assets/images/searchdocument.png";
import warning from "../assets/images/warning.png";
import plusIcon from "../assets/images/plusIcon.png";
import pencilIcon from "../assets/images/pencil.png";
import doneIcon from "../assets/images/done.png";
import cancelIcon from "../assets/images/cancel.png";
import userIcon from "../assets/images/user-line.png";
import unauthorizedImg from "../assets/images/unauthorized.png";
import importIcon from "../assets/images/import.png";
import uploadDocsIcon from "../assets/images/uploadDocsIcon.png";
import uploadIcon from "../assets/images/upload.png";

import noData from "../assets/images/nodatafound.png";


export const AppIcons = {

  // Menus & Header Images

  // Others
  plusIcon: plusIcon,
  calenderIcon: calender,
  logoImage: logo,
  logoImageLight: logoWhite,
  editIcon: edit,
  deleteIcon: deleted,
  deleteBtn: deleteBtn,
  copyIcon: copy,
  searchIcon: search,
  notificationIcon: notification,
  arrowIcon: arrow,
  ArrowRight: arrowRight,
  shortingArrowIcon: shortingArrow,
  noRecordImg: noRecord,
  documentIcon: documenticon,
  saveIcon: saveicon,
  AddIcon: addIcon,
  CloseIcon: closeIcon,
  CloseIconR: closeIconR,
  binoculars: binoculars,
  deletecolor: deletecolor,
  printer: printer,
  searchcolor: searchcolor,
  searchDocument: searchDocument,
  warning: warning,
  PencilIcon: pencilIcon,
  DoneIcon: doneIcon,
  CancelIcon: cancelIcon,
  userIcon: userIcon,
  UnauthorizedImg: unauthorizedImg,
  ImportIcon: importIcon,

  UploadDocsIcon: uploadDocsIcon,
  UploadIcon: uploadIcon,

  NoDataImg: noData,
};
