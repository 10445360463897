/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { getCookie } from '../../utils/Cookies/CookieHandler';
import Label from '../ui/label/Label'
import DropdownSelect from '../ui/dropdown/DropDrown';
import { useLazyGetServiceLocationQuery } from '../../app/services/commonAPI';
import { ServiceLocationContext } from '../../utils/context/ServiceLocationContext';
import PropTypes from 'prop-types';

const ServiceLocationFilter = (props) => {

    const { setIsLocationUpdated } = useContext(ServiceLocationContext)

    const cookie = getCookie("AuthUser");
    const userID = cookie?.user?.lUserKey;

    const [locationList, setLocationList] = useState(null);

    const [getServiceLoaction, {
        isFetching: isLocationFetching,
        isSuccess: isLocationFetched,
        data: locationData
    }] = useLazyGetServiceLocationQuery();

    useEffect(() => {
        if (userID) {
            getServiceLoaction(userID);
            setIsLocationUpdated(false)
        }
    }, [userID, props.selectedLocation, props.isLocationUpdated]);

    const handleChange = (selected) => {
        if (props.onLocationChange) {
            props.onLocationChange(selected)
        }
    }

    useEffect(() => {
        if (!isLocationFetching && isLocationFetched && locationData) {
            const dropDownField = locationData?.map((item) => ({
                label: item.sServiceLocation,
                value: item.lServiceLocationKey
            }));
            setLocationList(dropDownField)
        }
    }, [isLocationFetching, isLocationFetched, locationData])

    return (
        <div className="input-label-part">
            <Label labelName="Service Location :" />
            <DropdownSelect
                value={props.selectedLocation}
                options={locationList}
                placeholder="Select Location"
                onChange={handleChange}
                isDisabled={props.isDisable || locationData?.length === 1}
            />
        </div>
    )
}

ServiceLocationFilter.propTypes = {
    selectedLocation: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    isLocationUpdated: PropTypes.bool,
    isDisable: PropTypes.bool,
    onLocationChange: PropTypes.func,
};

export default ServiceLocationFilter